// We have to define the overrides first and then include bootstrap,
// see https://getbootstrap.com/docs/4.1/getting-started/theming/#variable-defaults
$theme-colors: (
  'primary': #009374,
  'dark': #203726,
  'light-green': #bfd466
);

$dark-green: #203726;
$yellow: #e1c90c;

@import 'bootstrap-scss';

/* Important for case without content so that the footer will be at the end of the viewport */
main {
  min-height: 80vh;
}

/* Aligning the badge on the bottom left of the card-img */
.dd-category {
  bottom: 0;
  left: 0;
}

/* Styles the calendar icon of events in the top bar above the card-img */
.calendar-icon {
  max-width: 1.5rem;
  max-height: 1.5rem;
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

/* Remove hover effect from the page count of the pagination */
.page-link.page-count:hover {
  background-color: $body-bg;
}

span.badge {
  font-size: 100%;
  line-height: 1.5;
}

.disabled-button {
  pointer-events: none;
  opacity: 0.4;
}

.divider {
  background-color: $dark-green;
  border-bottom: 11px solid $yellow;
  height: 27px;
  width: 100%;
}

.divider-small {
  background-color: $dark-green;
  border-bottom: 6.5px solid $yellow;
  height: 16px;
  width: 100%;
}

.filter-container {
  box-shadow: 3px 3px 11px #00000029;
  border: 1px solid #bababa;
  border-radius: 14px;
  width: 20rem;
}

.filter-subtitle {
  text-align: left;
  letter-spacing: 0;
  color: #646464;
  opacity: 1;
}

.filter-label {
  font-size: 20px;
  text-align: left;
  letter-spacing: 0;
  color: $dark-green;
  opacity: 1;
}

.header-subtitle {
  text-align: left;
  font: normal normal normal 30px/36px;
  letter-spacing: 0;
  color: #112818;
  opacity: 1;
}

.header-main-subtitle {
  font-size: 2rem;
}

.header-img-bottom {
  height: 250px;
  object-fit: cover;
  object-position: 50% 58%;
  width: 100%;
}

.headerImageHolder {
  position: relative;
}

#headerImageHolderImg
{
  height: 250px;
  object-fit: cover;
  object-position: 50% 58%;
  width: 100%;
}

#headerImageHolderSpan
{
  position: absolute;
  bottom: 8px;
  right: 16px;
  color:#D1D1D1;
  font-size: 14px;
}

.card {
  border-radius: 14px;
  box-shadow: 4px 4px 8px #00000029;
  height: 36rem;
  width: 25rem;
}

.card-footer {
  background-color: $dark-green;
  text-align: center;
  border-radius: 0 0 14px 14px !important;
}

#cardfooter a:link {
  color: white;
  text-transform: uppercase;
}

.card-category {
  background-color: $dark-green;
}

.card-event-date {
  border-radius: 14px 14px 0 0;
  background-color: $dark-green;
}

.card-img-top-container {
  border-radius: 14px 14px 0 0;
}

.card-img-top {
  height: 300px;
  object-fit: cover;
  object-position: 50% 20%;
  border-radius: 14px 14px 0 0;
}

.card-title {
  font: normal normal bold 28px/34px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-body {
  padding: 0.75rem;
}

.card-text {
  text-align: left;
  font: normal normal medium 25px/30px;
  letter-spacing: 0;
  color: #646464;
  opacity: 1;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.back-btn {
  color: $dark-green;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}
